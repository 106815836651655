import NotificationSound from "@samedaycustom/assets/sounds/Popcorn.wav";
import { OrderNotification, PrimaryButton, useModal, Text, Box } from "@samedaycustom/core-ui";
import { events as EVENTS } from "@samedaycustom/helpers";
import { useInternetStatus, useToast } from "@samedaycustom/hooks";
import { ESDCPusherEvents } from "@samedaycustom/sdc-types";
import { ACTION_TYPE_DEFS } from "@samedaycustom/types/app";
import { ISDCMessage } from "@samedaycustom/types/app/Messaging";
import { ISDCNote } from "@samedaycustom/types/order/@types/notes";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import { pusher } from "admin/config";
import { partlyUpdateOrder, updateOrders } from "admin/providers/actions/orders";
import { UPDATE_ORDER_MESSAGE_READ } from "admin/providers/actions/orders/type";
import { EventsState } from "admin/providers/features/events";
import {
  markCustomerMessagesAsUnread,
  markLocationMessageAsRead,
  markLocationMessagesAsUnread,
} from "admin/providers/features/message";
import {
  getCustomerUnreadMesageCount,
  getVendorUnreadMesageCount,
} from "admin/providers/features/message/async";
import { ADMIN_ORDER_STATE_TYPE, ORDERS_DATA } from "admin/providers/reducers/orders/initialState";
import { VIEW_ORDER } from "admin/routes/screen";
import { Channel } from "pusher-js";
import React, { createContext, useEffect } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  realTimeUpdateAgents,
  realTimeUpdateDeliveryMethod,
  realTimeUpdateDueDate,
  realTimeUpdateStatus,
  updateCarDeliveryRecordStatus,
  updateDeliveryRecordStatus,
  realTimeUpdateArtwork,
} from "../../providers/actions/app";
import useMessage, { IUserType } from "../hooks/useMessage";
import useNativeNotify from "../hooks/useNativeNotify";

import * as EVENTSSDC from "admin/helpers/constants/events";
const NotificationContext = createContext(null);

export interface INotificationContext {
  children: React.ReactNode;
  orderApi: string;
}
export default React.memo(({ children }: INotificationContext) => {
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const modal = useModal();
  const { alertNetwork } = useInternetStatus();
  const { sendMessage, updateAdminMesssages, updateMesssages } = useMessage();
  const orders = useSelector<
    {
      orders: ADMIN_ORDER_STATE_TYPE;
    },
    ACTION_TYPE_DEFS<ORDERS_DATA>
  >((state) => state.orders.ordersList);

  const auth = useSelector((state) => state["auth"]);
  const events = useSelector<DefaultRootState, EventsState>((state) => state["events"]);
  const [newOrder, setNewOrder] = React.useState<ISDCOrder>(null);
  const [unAssignedOrder, setUnAssignedOrder] = React.useState<ISDCOrder>(null);

  const { notifyme } = useNativeNotify(NotificationSound);
  const channel = React.useRef<Channel[]>([]);

  useEffect(() => {
    requestNotificationPermission();
    return () => {
      if (channel.current.length && !auth?.token) {
        channel.current.forEach((channel) => channel.disconnect());
      }
    };
  }, []);

  useEffect(() => {
    const channels = events.pusher_channels;

    if (!channel.current.length) {
      channels.forEach((o: string) => {
        const currentChannel = pusher.subscribe(o);
        binder(currentChannel);
        channel.current.push(currentChannel);
      });
    }
  }, [events.pusher_channels]);

  useEffect(() => {
    pusher.connection.bind("unavailable", () => alertNetwork("closed"));
    pusher.connection.bind("connecting", () => alertNetwork("connecting"));
    pusher.connection.bind("connected", () => alertNetwork("open"));
  }, []);

  useEffect(() => {
    const foundOrderAlreadyInList = orders?.data?.findIndex((o) => o?.id === newOrder?.id);

    if (foundOrderAlreadyInList === -1 && newOrder?.id) {
      dispatch(updateOrders([newOrder], "new"));
      /**
       *
       * send browser notification for new order
       */
      notifyme({
        type: "order",
        orderDecoId: newOrder?.decoOrderId,
        orderNumber: newOrder?.id,
        message: `Order on store: ${newOrder?.locationName}`,
      });
      setNewOrder(null);
    }
  }, [orders, newOrder]);

  useEffect(() => {
    /**
     *
     * send browser notification for unassigned order
     */
    if (unAssignedOrder && !unAssignedOrder?.locationID) {
      notifyme({
        type: "unasssigned_order",
        orderDecoId: unAssignedOrder?.decoOrderId,
        orderNumber: unAssignedOrder?.id,
        message: `Order on store: ${unAssignedOrder?.locationName}`,
      });
      setUnAssignedOrder(null);
    }
  }, [unAssignedOrder]);

  function binder(channel: Channel) {
    channel.bind(EVENTS.events.UNASSIGNED_ORDER, realTimeUpdateUnassignedOrder);
    channel.bind(EVENTS.events.NEW_MESSAGE_ADMIN, updateAdminMesssages);
    channel.bind(EVENTS.events.NEW_MESSAGE, (payload: any) => {
      updateMesssages(payload);
      dispatch({
        type: UPDATE_ORDER_MESSAGE_READ,
        payload: {
          orderID: payload?.orderID,
          isNew: payload?.unread?.admin > 0,
        },
      });
    });
    channel.bind(EVENTS.events.STORE_MESSAGE_READ, ({ location_id }: { location_id: string }) => {
      dispatch(getVendorUnreadMesageCount());
      dispatch(markLocationMessageAsRead(location_id));
    });
    channel.bind(EVENTS.events.CUSTOMER_MESSAGE_READ, ({ order_id }: { order_id: string }) => {
      dispatch(getCustomerUnreadMesageCount());
      dispatch({
        type: UPDATE_ORDER_MESSAGE_READ,
        payload: { orderID: order_id },
      });
    });
    channel.bind(EVENTS.events.NEW_ORDER, realTimeUpdateOrderAndNotifyUser);
    channel.bind(EVENTS.events.ORDER_STATUS_UPDATED, realTimeUpdateOrderStatus);
    channel.bind(EVENTS.events.DELIVERY_STATUS_UPDATE, updateDeliveryStatus);
    channel.bind(EVENTS.events.ORDER_AGENTS_UPDATED, realTimeUpdateOrderAgents);
    channel.bind(EVENTS.events.ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE, realTimeUpdateOrderDueDate);
    channel.bind(EVENTS.events.ORDER_DELIVERY_METHOD_UPDATED, realTimeUpdateOrderDeliveryMethod);
    channel.bind(EVENTS.events.ORDER_ARTWORK_CHANGE_UPDATE, realTimeUpdateOrderArtwork);
    channel.bind(EVENTS.events.NEW_QUOTE_REQUEST, realTimeQuoteRequestNotify);
    channel.bind(
      EVENTS.events.ORDER_CAR_DELIVERY_STATUS_UPDATED,
      ({
        orderId,
        trackingId,
        deliveryCarStatus,
      }: {
        orderId: string;
        trackingId: string;
        deliveryCarStatus: string;
      }) =>
        dispatch(updateCarDeliveryRecordStatus({ orderId, trackingId, status: deliveryCarStatus }))
    );
    // TODO: For locationId update
    channel.bind(
      ESDCPusherEvents.UNREAD_MESSAGES_THREAD,
      ({ orderIds, locationIds }: { orderIds: string[]; locationIds: [] }) => {
        if (orderIds?.length) {
          dispatch(markCustomerMessagesAsUnread(orderIds));
        }

        if (locationIds?.length) {
          dispatch(markLocationMessagesAsUnread(locationIds));
        }
      }
    );

    channel.bind(EVENTSSDC.events.EDITED_PAYOUT_VALUE, (data: any) => {
      dispatch(partlyUpdateOrder(data));
    });
    channel.bind("LOCATION_LANDING_PAGE", (data: any) => {
      if (data.error) {
        toast({
          title: "Landing Page Error",
          description: data.message || "Error creating landing page",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-left",
        });
      } else {
        toast({
          title: "Landing Page Updated",
          description: data.message || "Landing Page Created Successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-left",
        });
      }
    });
  }
  // request browser request
  function requestNotificationPermission() {
    if ("Notification" in window && !(Notification.permission === "granted")) {
      Notification.requestPermission().then(function(permission) {
        if (!(permission === "granted")) {
          alert(
            "To recieve notifications, please enable notifications for this webpage in your browser settings."
          );
        }
      });
    }
  }
  // update unaassigned order at realtime
  async function realTimeUpdateUnassignedOrder(order: ISDCOrder) {
    /**
     *
     * listen to unassigned order events and show toast
     */

    if (order?.id && (!order?.locationID || !order?.vendorID)) {
      await dispatch(updateOrders([order], "new"));
      toast({
        title: "Unassigned Order",
        description: (onClose) => {
          return (
            <Box background="#E12D39">
              <Text paddingBottom="5px">{order?.decoOrderId} has been unassigned</Text>
              <PrimaryButton
                background="#EF4E4E"
                backgroundColor="#EF4E4E"
                color="#fff"
                _active={{
                  background: "#EF4E4E",
                  outline: "none",
                  border: "none",
                }}
                _focus={{
                  background: "#EF4E4E",
                  outline: "none",
                  border: "none",
                }}
                _hover={{
                  background: "#EF4E4E",
                  outline: "none",
                  border: "none",
                }}
                _disabled={{
                  background: "#EF4E4E",
                  outline: "none",
                  border: "none",
                }}
                onClick={() => {
                  onClose();
                  history?.push(`${VIEW_ORDER}/${order.id}`);
                }}
              >
                View order
              </PrimaryButton>
            </Box>
          );
        },
        position: "bottom-left",
        status: "error",
        duration: 9999999,
        isClosable: true,
      });
      notifyme({
        type: "unasssigned_order",
        orderDecoId: order?.decoOrderId,
        orderNumber: order?.id,
        message: `Order on store: ${order?.locationName}`,
      });
    }
  }

  // update order at real time
  function realTimeUpdateOrderAndNotifyUser(socketdata: ISDCOrder) {
    try {
      if (socketdata) {
        const formattedData = socketdata;

        formattedData.isNew = true;
        setNewOrder(formattedData);
      }
    } catch (error) {
      null; // TODO: handle error
    }
  }

  /**
   * send a dispatch signal ORDER_STATUS_UPDATED
   * with the orderId and Status
   *  as the property then any REDUCER
   * listening to that ACTION can do whaterver it wants with it
   */

  function realTimeUpdateOrderStatus(order: any) {
    dispatch(realTimeUpdateStatus(order.order_id, order.status));
    setUnAssignedOrder(order);
  }

  function realTimeUpdateOrderAgents(order: any) {
    dispatch(realTimeUpdateAgents(order.order_id, order.assignedAgents));
  }

  function updateDeliveryStatus(info: any) {
    dispatch(updateDeliveryRecordStatus(info));
  }

  function realTimeUpdateOrderDeliveryMethod(order: any) {
    dispatch(realTimeUpdateDeliveryMethod(order.orderId, order.deliveryMethod));
  }

  function realTimeUpdateOrderArtwork(order: any) {
    dispatch(realTimeUpdateArtwork(order.orderId, order.status));
  }

  function realTimeQuoteRequestNotify(info: any) {
    if (!info.locationId) {
      toast({
        title: "New Quote Request",
        description: `New quote request from ${info?.customerName}`,
        status: "success",
        position: "bottom-left",
      });
    }
  }

  function realTimeUpdateOrderDueDate(order: any) {
    dispatch(
      realTimeUpdateDueDate(
        order.orderId,
        order.decoOrderId,
        order.timeZone,
        order.status,
        order.newDueDate
      )
    );
  }

  return (
    <NotificationContext.Provider value={{ sendMessage }}>{children}</NotificationContext.Provider>
  );
});
export const useNotificationContext = () =>
  React.useContext<{
    sendMessage: (type: IUserType, messageBody: ISDCMessage) => void;
    sendNote: (messageBody: Partial<ISDCNote>) => void;
    markAsRead: (orderID: string) => void;
  }>(NotificationContext);
