export const channels = {
  ADMIN: "ADMIN-NEW",
  VENDOR: "VENDOR",
  CUSTOMER: "CUSTOMER"
};
export const events = {
  UNASSIGNED_ORDER: "UNASSIGNED_ORDER",
  REASSIGNED_REMOVE_ORDER: "REASSIGNED_REMOVE_ORDER",
  NEW_ORDER: "NEW_ORDER",
  NEW_NOTIFICATION: "NEW_NOTIFICATION",
  ORDER_STATUS_UPDATED: "ORDER_STATUS_UPDATED",
  ORDER_AGENTS_UPDATED: "ORDER_AGENTS_UPDATED",
  NEW_MESSAGE: "NEW_MESSAGE",
  STORE_MESSAGE_READ: "STORE_MESSAGE_READ",
  CUSTOMER_MESSAGE_READ: "CUSTOMER_MESSAGE_READ",
  NEW_MESSAGE_ADMIN: "NEW_MESSAGE_ADMIN",
  NEW_NOTE: "NEW_NOTE",
  DELAYED_ORDER: "DELAYED_ORDER",
  MESSAGE_NOTIFICATION: "MESSAGE_NOTIFICATION",
  DELIVERY_STATUS_UPDATE: "delivery-status-update",
  BROADCAST: "BROADCAST",
  BROADCAST_ALL: "BROADCAST_ALL",
  ORDER_CAR_DELIVERY_STATUS_UPDATED: "ORDER_CAR_DELIVERY_STATUS_UPDATED",
  ORDER_FINANCIALS_DOWNLOADS_READY: "ORDER_FINANCIALS_DOWNLOADS",
  ORDER_DELIVERY_METHOD_UPDATED: "ORDER_DELIVERY_METHOD_UPDATED",
  ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE: "ORDER_DUE_DATE_CHANGE_REQUEST_UPDATE",
  ORDER_PAYOUT_DOWNLOADS: "ORDER_PAYOUT_DOWNLOADS",
  ORDER_ARTWORK_CHANGE_UPDATE: "ORDER_ARTWORK_CHANGE_UPDATE",
  NEW_QUOTE_REQUEST: "NEW_QUOTE_REQUEST",
};
